<template>
    <v-row wrap>
        <v-col cols="12" md="4" class="text-left">
            <p>Digitally Generated Leads, allocated in real time (zero days old!). Leads generated from Quility.com & SfgLife.com. Only Standing are accepted.</p>
        </v-col>
        <v-col cols="12" md="4" v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])">
            <q-agent-lookup :filters="{'Status' : 'Active,Available,Stalled'}" label="Agent" v-model="AgentCode" placeholder="Type to Search for Agent"></q-agent-lookup>
        </v-col>
        <v-col v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])" cols="12" md="4">
            <v-btn :disabled="disabled_button" color="primary" large @click="startNewOrder()">
                Create New Order
                <v-icon right>fas fa-cart-plus</v-icon>
            </v-btn>
        </v-col>
        <v-col v-else-if="user.Agent.Status == 'Active'" cols="12" md="4">
            <v-btn color="primary" large @click="startNewOrder()">
                Create New Order
                <v-icon right>fas fa-cart-plus</v-icon>
            </v-btn>
        </v-col>
        <v-col v-else cols="12">
            <v-alert color="orange">
                Sorry. You cannot create new lead order until your account is "Active". Please contact your Agency Owner to re-activate your account.
            </v-alert>
        </v-col>
        <v-col cols="12">
            <q-digital-lead-order-data-table :lead-types="['Quility Digital Lead', 'Lighthouse']" v-on:editorder="editOrder" v-on:reopenorder="reopenOrder" ref="lead_order_datatable" :agent-code="AgentCode" title="Quility Digital Lead Orders" key="quility_digital_leadorder_data"></q-digital-lead-order-data-table>
        </v-col>
        <v-dialog ref="new_digital_order_dialog" v-model="showEditLeadOrder" :width="850">
            <v-card class="text-center">
                <q-edit-digital-lead-order-form title="Quility Digital Lead Order Form" key="MyDigitalLeadOrders" ref="lead_order_form" :agent-code="agent_code" :id="current_order_id" v-on:order_saved="orderSaved" v-on:cancel="showEditLeadOrder = false" lead-type="Quility Digital Lead" lead-level="DLHA"></q-edit-digital-lead-order-form>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import QEditDigitalLeadOrderForm from './QEditDigitalLeadOrderForm.vue'
import QDigitalLeadOrderDataTable from '@/components/datatables/LeadOrders/QDigitalLeadOrderDataTable.vue'
export default {
    props: [],
    data() {
        return {
            showEditLeadOrder: false,
            AgentCode: null,
            order_AgentCode: null,
            current_order_id: 'new'
        }
    },
    methods: {
        orderSaved: function() {
            this.showEditLeadOrder = false
            this.$refs.lead_order_datatable.refresh();
            this.$refs.lead_order_form.reset();
        },
        editOrder(item) {
            var g = this
            this.order_AgentCode = item.AgentCode;
            this.AgentCode = null;
            this.current_order_id = item.ID
            var order = {
                OrderType: item.OrderType,
                AgentCode: item.agentCode,
                Qty: item.Qty,
                LeadType: item.LeadType,
                LeadOrderLines: [],
                OrderDate: item.OrderDate
            }
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                order.OrderStatus = item.OrderStatus;
            }
            item.lead_order_lines.forEach(function(line) {
                if (line.OrderLineStatus == 'Open' || line.OrderLineStatus == 'Needs Approval') {
                    var l = {
                        "ID": line.ID,
                        "LeadType": line.LeadType,
                        "State": line.State,
                        "Qty": line.QtyRequested,
                        "ProductType": line.ProductType,
                        "County": line.County,
                        "OrderLineStatus": line.OrderLineStatus
                    }
                    order.LeadOrderLines.push(l)
                }
            })
            this.showEditLeadOrder = true
            this.$nextTick(function() {
                g.$refs.lead_order_form.order = order;
                g.$refs.lead_order_form.reset();
            })
        },
        reopenOrder(item) {
            var g = this
            this.order_AgentCode = item.AgentCode;
            this.AgentCode = null;
            this.current_order_id = item.ID
            var order = {
                OrderType: item.OrderType,
                AgentCode: item.agentCode,
                Qty: item.Qty,
                LeadType: item.LeadType,
                LeadOrderLines: [],
                OrderDate: item.OrderDate
            }
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                order.OrderStatus = item.OrderStatus;
            }
            item.lead_order_lines.forEach(function(line) {
                if (line.OrderLineStatus == 'Complete' && item.OrderStatus == 'Complete') {
                    var l = {
                        "ID": line.ID,
                        "LeadType": line.LeadType,
                        "State": line.State,
                        "Qty": line.QtyRequested,
                        "ProductType": line.ProductType,
                        "County": line.County,
                        "OrderLineStatus": 'Open'
                    }
                    order.LeadOrderLines.push(l)
                }
                if (line.OrderLineStatus == 'Canceled' && (item.OrderStatus == 'Canceled' || item.OrderStatus == 'Pending Cancellation')) {
                    var l = {
                        "ID": line.ID,
                        "LeadType": line.LeadType,
                        "State": line.State,
                        "Qty": line.QtyRequested,
                        "ProductType": line.ProductType,
                        "County": line.County,
                        "OrderLineStatus": 'Open'
                    }
                    order.LeadOrderLines.push(l)
                }
            })
            this.showEditLeadOrder = true
            this.$nextTick(function() {
                g.$refs.lead_order_form.order = order;
                g.$refs.lead_order_form.reset();
            })
        },
        startNewOrder() {
            this.current_order_id = "new"
            this.showEditLeadOrder = true
            this.order_AgentCode = null;
            var g = this
            this.$nextTick(function() {
                g.$refs.lead_order_form.reset();
            })
        }
    },
    computed: {
        'disabled_button': function() {
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec']) && this.AgentCode == null) {
                return true;
            }
            return false;
        },
        'agent_code': function() {
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                return this.order_AgentCode ? this.order_AgentCode : this.AgentCode;
            }
            return this.user.Agent.AgentCode
        }
    },
    watch: {},
    components: {
        QAgentLookup,
        QEditDigitalLeadOrderForm,
        QDigitalLeadOrderDataTable
    }
}
</script>