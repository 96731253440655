<template>
	<v-row>
			<div style="width:100%; background-color:#46c3b2;  padding:0.5em 0 0 0; text-align:center;">
				<h1 style="color:#fff;margin-bottom:0.15em;">Digital Economy Leads</h1>
			</div>
		<v-row wrap class="mt-6">
			<v-col cols="12" md="4" class="text-left">
				<p>{{ description }}</p>
			</v-col>
			<v-col cols="12" md="4" v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])">
				<q-agent-lookup :filters="{ Status: 'Active,Available,Stalled', attr: 'StateLicenses' }" label="Agent"
					v-model="AgentCode" placeholder="Type to Search for Agent" />
			</v-col>
			<v-col v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])" cols="12" md="4">
				<v-btn :disabled="disabled_button" color="primary" large @click="startNewOrder()">
					Create New Order
					<v-icon right>fas fa-cart-plus</v-icon>
				</v-btn>
			</v-col>
			<v-col v-else-if="user.Agent.Status == 'Active'" cols="12" md="4">
				<v-btn color="primary" large @click="startNewOrder()">
					Create New Order
					<v-icon right>fas fa-cart-plus</v-icon>
				</v-btn>
			</v-col>
			<v-col v-else cols="12">
				<v-alert color="orange">
					Sorry. You cannot create new lead order until your account is
					"Active". Please contact your Agency Owner to re-activate your
					account.
				</v-alert>
			</v-col>
			<v-col cols="12">
				<q-digital-lead-order-data-table :lead-types="['Economy']" v-on:editorder="editOrder"
					v-on:reopenorder="reopenOrder" ref="lead_order_datatable" :agent-code="AgentCode"
					title="Digital Economy Lead Orders" key="quility_digital_leadorder_data" />
			</v-col>
			<v-dialog ref="new_digital_order_dialog" v-model="showEditLeadOrder" :width="850">
				<v-card class="text-center">
					<q-edit-digital-lead-order-form :description="description"
						title="Digital Economy Lead Order Form" key="MyDigitalLeadOrders" ref="lead_order_form"
						:agent-code="agent_code" :id="current_order_id" v-on:order_saved="orderSaved"
						v-on:cancel="showEditLeadOrder = false" />
				</v-card>
			</v-dialog>
		</v-row>
	</v-row>
</template>
<script>
import QAgentLookup from "@/components/utils/QAgentLookup.vue";
import QEditDigitalLeadOrderForm from "./EditDigitalEconomyLeadForm.vue";
import QDigitalLeadOrderDataTable from "@/components/datatables/LeadOrders/QDigitalLeadOrderDataTable.vue";

export default {
	components: {
		QAgentLookup,
		QEditDigitalLeadOrderForm,
		QDigitalLeadOrderDataTable,
	},
	data() {
		return {
			showEditLeadOrder: false,
			AgentCode: null,
			order_AgentCode: null,
			current_order_id: "new",
            description: 'Economy leads are lower-priced, lower-intent leads that are best used to practice and gain initial experience with digital leads. Due to a limited supply and overwhelming demand, we are prioritizing agents who are new to digital leads and have written at least 6 apps. If you don\'t yet have 6 apps, we recommend ordering B leads first. For experienced agents who don\'t need low-cost practice attempts, we highly recommend you place an order for higher-intent Lighthouse leads',
		};
	},
	methods: {
		orderSaved: function () {
			this.showEditLeadOrder = false;
			this.$refs.lead_order_datatable.refresh();
			this.$refs.lead_order_form.reset();
		},
		showEditOrderForm(order) {
			this.showEditLeadOrder = true;
			this.$nextTick(() => {
				this.$refs.lead_order_form.order = order;
				this.$refs.lead_order_form.reset();
			});
		},
		editOrder(item) {
			this.order_AgentCode = item.AgentCode;
			this.AgentCode = null;
			this.current_order_id = item.ID;
			var order = {
				...item,
				LeadOrderLines: [],
			};
			if (this.hasRole(["Staff", "SuperAdmin", "Exec"])) {
				order.OrderStatus = item.OrderStatus;
			}
			item.lead_order_lines.forEach(function (line) {
				if (
					line.OrderLineStatus == "Open" ||
					line.OrderLineStatus == "Needs Approval" ||
					line.OrderLineStatus == "Paid Overflow" ||
					line.OrderLineStatus == "Unpaid Overflow"
				) {
					var l = {
						"ID": line.ID,
						"LeadType": line.LeadType,
						"State": line.State,
						"Qty": line.QtyRequested,
						"ProductType": line.ProductType,
						"County": line.County,
						"OrderLineStatus": line.OrderLineStatus
					}
					order.LeadOrderLines.push(l);
				}
			});
			this.showEditOrderForm(order);
		},
		reopenOrder(item) {
			this.order_AgentCode = item.AgentCode;
			this.AgentCode = null;
			this.current_order_id = item.ID;
			const order = {
				...item,
				LeadOrderLines: [],
			};
			if (this.hasRole(["Staff", "SuperAdmin", "Exec"])) {
				order.OrderStatus = item.OrderStatus;
			}
			item.lead_order_lines.forEach(function (line) {
				if (
					line.OrderLineStatus == "Complete" &&
					item.OrderStatus == "Complete"
				) {
					order.LeadOrderLines.push({
						...line,
						OrderLineStatus: "Open",
					});
				}
				if (
					line.OrderLineStatus == "Canceled" &&
					item.OrderStatus == "Canceled"
				) {
					order.LeadOrderLines.push({
						...line,
						OrderLineStatus: "Open",
					});
				}
			});
			this.showEditOrderForm(order);
		},
		startNewOrder() {
			this.current_order_id = "new";
			this.showEditLeadOrder = true;
			this.order_AgentCode = null;
			this.$nextTick(() => {
				this.$refs.lead_order_form.reset();
			});
		},
	},
	computed: {
		disabled_button() {
			return Boolean(this.hasRole(["Staff", "SuperAdmin", "Exec"]) && this.AgentCode == null)
		},
		agent_code() {
			if (this.hasRole(["Staff", "SuperAdmin", "Exec"])) {
				return this.order_AgentCode
					? this.order_AgentCode
					: this.AgentCode;
			}
			return this.user.Agent.AgentCode;
		},
	},
};
</script>
